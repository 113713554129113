<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
        <CTab active>
          <template slot="title">
            <CIcon name="cil-task" />&nbsp;Filtre
          </template>
          <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="6">
                <CInput
                  label="Email"
                  placeholder="E-mail adres"
                  type="email"
                  horizontal
                  v-on:keyup.enter="filter"
                  v-model="data.emailAddress"
                />
              </CCol>
              <CCol col="3" sm="2">
                <CButton
                  type="submit"
                  size="sm"
                  color="primary"
                  @click="filter()"
                >
                  <CIcon name="cil-check-circle" />
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton
                  type="reset"
                  size="sm"
                  color="danger"
                  @click="clearFilters"
                >
                  <CIcon name="cil-ban" />
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  type="number"
                  label="Kullanıcı Id"
                  placeholder="000000"
                  horizontal
                  v-on:keyup.enter="filter"
                  v-model="data.id"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Ad"
                  placeholder="Müşteri adı"
                  horizontal
                  v-on:keyup.enter="filter"
                  v-model="data.firstName"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Soyad"
                  placeholder="Müşteri soyadı"
                  horizontal
                  v-on:keyup.enter="filter"
                  v-model="data.lastName"
                />
              </CCol>
            </CRow>
            <hr />
            <CRow>
              <CCol>
                <CInput
                  label="Üyelik Tarihi"
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.startTime"
                />
                <CInput
                  label=" "
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.endTime"
                />
              </CCol>
            </CRow>
          </CForm>
        </CTab>
    </CTabs>
</template>

<script>
   export default{
       name: "CustomerFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
           }
       },
       methods:{
            clearFilters: function(){
                this.data = {}
                this.filter()
            }
       }
   }
</script>